import { NgModule } from '@angular/core'
import {
	OptionsMenuComponent,
	PieChartComponent,
	StelvioGridBodyComponent,
	StelvioGridComponent,
	ToggleComponent,
	TaskOverviewComponent,
	WorkflowListItemComponent,
	StelvioSpinnerComponent,
	StelvioModalComponent,
	ContactButtonsComponent,
	ResponsiblesAvatarComponent,
	ToastComponent,
	ToasterComponent,
} from './components'
import { ReactiveFormsModule } from '@angular/forms'
import { CommonModule } from '@angular/common'
import {
	ProductsFacade,
	MessagesFacade,
	ActivitiesFacade,
	BatchsFacade,
	WorkflowsFacade,
	CompaniesFacade,
	TrackingFacade,
	FacilitiesFacade,
	CountriesFacade,
	ImagesDocumentsFacade,
	CommonFacade,
} from './services'
import { ProductsRequest } from './requests/products.request'
import { MessagesRequest } from './requests/messages.request'
import { ActivitiesRequest } from './requests/activities.request'
import { BatchsRequest } from './requests/batchs.request'
import { NgApexchartsModule } from 'ng-apexcharts'
import { MembersRequest } from '../members/requests/members.request'
import { MembersFacade } from '../members/services/members.facade'
import { PendingChangesGuard } from './guards/pending-changes.guard'
import { WorkflowRequest } from './requests/workflows.request'
import { CompaniesRequest } from './requests/companies.request'
import { InlineSVGModule } from 'ng-inline-svg-2'
import { MatMenuModule } from '@angular/material/menu'
import { AccessControlDirective } from './directives'
import { TrackingRequest } from './requests/tracking.request'
import { FacilitiesRequest } from './requests/facilities.request'
import { ImagesDocumentsRequest } from './requests/imagesDocuments.request'
import { CountriesRequest } from './requests/countries.request'
import { TranslationModule } from '../i18n'
import { NgbModule } from '@ng-bootstrap/ng-bootstrap'
import { MatButtonToggleModule } from '@angular/material/button-toggle'
import { OrderByPipe } from './pipes/order-by.pipe'
import { Ng2FlatpickrModule } from 'ng2-flatpickr'
import { CommonRequest } from './requests/common.request'

@NgModule({
	declarations: [
		ToggleComponent,
		StelvioGridComponent,
		OptionsMenuComponent,
		StelvioGridBodyComponent,
		PieChartComponent,
		TaskOverviewComponent,
		WorkflowListItemComponent,
		StelvioSpinnerComponent,
		StelvioModalComponent,
		AccessControlDirective,
		ContactButtonsComponent,
		ResponsiblesAvatarComponent,
		ToasterComponent,
		ToastComponent,
		OrderByPipe,
	],
	providers: [
		ProductsRequest,
		ProductsFacade,
		MessagesRequest,
		MessagesFacade,
		ActivitiesRequest,
		ActivitiesFacade,
		ImagesDocumentsFacade,
		BatchsRequest,
		BatchsFacade,
		MembersRequest,
		MembersFacade,
		WorkflowRequest,
		WorkflowsFacade,
		PendingChangesGuard,
		CompaniesRequest,
		CompaniesFacade,
		CommonFacade,
		CommonRequest,
		TrackingRequest,
		TrackingFacade,
		FacilitiesRequest,
		ImagesDocumentsRequest,
		FacilitiesFacade,
		CountriesRequest,
		CountriesFacade,
	],
	imports: [ReactiveFormsModule, Ng2FlatpickrModule, CommonModule, NgApexchartsModule, InlineSVGModule, MatMenuModule, TranslationModule, NgbModule, MatButtonToggleModule],
	exports: [
		ToggleComponent,
		StelvioGridComponent,
		OptionsMenuComponent,
		StelvioGridBodyComponent,
		PieChartComponent,
		TaskOverviewComponent,
		WorkflowListItemComponent,
		StelvioSpinnerComponent,
		StelvioModalComponent,
		AccessControlDirective,
		ToasterComponent,
		ToastComponent,
		OrderByPipe,
	],
})
export class CommonStelvioModule {}
